import React from 'react';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import { Box } from 'rebass';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import { ArticleContainer } from '../../components/ui/ArticleContainer';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    //TODO
    allMdx: { mdxGroup },
    site: {
      siteMetadata: { title, siteUrl },
    },
  },
}) => (
  <Layout sidebarContent={null}>
    <Helmet>
      <title>{`Tags | ${title}`}</title>
      <link rel="canonical" href={`${siteUrl}/articles/tags`} />
      <meta property="og:url" content={`${siteUrl}/articles/tags`} />
      <meta name="twitter:url" content={`${siteUrl}/articles/tags`} />
      <meta name="robots" content="noindex" />
    </Helmet>
    <ArticleContainer>
      <div className="mx-auto prose prose-lg text-gray-600 prose-indigo">
        <div>
          <h1>Tags</h1>
          <Box mt={3}>
            <ul>
              {group.map((tag) => (
                <li key={tag.fieldValue}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </Box>
        </div>
      </div>
    </ArticleContainer>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    allMdx(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
