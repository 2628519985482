import React from 'react';

export const ArticleContainer = ({ children }) => (
  <div className="relative pb-16 overflow-hidden">
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div
        className="relative h-full mx-auto text-lg max-w-prose"
        aria-hidden="true"
      >
        {children}
      </div>
    </div>
  </div>
);
